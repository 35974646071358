window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});


document.addEventListener('DOMContentLoaded', function() {
    var userAgentField = document.querySelector('input[data-variable="userAgent"]');
    if (userAgentField) {
        userAgentField.value = navigator.userAgent;
        console.log("User-Agent value set to:", userAgentField.value);
    } else {
        console.log("User-Agent field not found.");
    }  var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://pro.ip-api.com/json?key=uDBlDLE4QL19zpt', true);
    xhr.onload = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            var data = JSON.parse(xhr.responseText);

            var ip_address = data.query;

            if (typeof ip_address != "undefined" || ip_address != null || ip_address != "") {
				document.querySelector('input[data-variable="ip_address"]').value = ip_address;
            }
            
            var zip = data.zip;

            if (typeof zip != "undefined" || zip != null || zip != "") {
				document.querySelector('input[data-variable="zip"]').value = zip;
            }

        } else {
            console.error(xhr.statusText);
        }
    };
    xhr.onerror = function () {
        console.error(xhr.statusText);
    };
    xhr.send(null);
});
